

























































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const LocationModule = namespace("LocationModule");
const BusinessModule = namespace("BusinessModule");
import { Location } from "../../store/modules/location/types";
import { Business } from "../../store/modules/business/types";
import Icon from "../../components/Icon.vue";
import LocationAdd from './LocationAdd.vue';
import { sortStringAttributes } from '../../services';

@Component({
  components: {
    Icon,
    LocationAdd
  }
})
export default class LocationList extends Vue {
  @LocationModule.Getter
  public locations!: Location[];

  @BusinessModule.Getter
  public businesses: Business[];

  public searchTerm = "";
  public fetching = false;
  public profile = JSON.parse(localStorage.getItem('profile') || '{}') || {}

  public activeMode = "add";
  public activeItem:any = {};

  public columns = [
    {
      title: "Name & Address",
      scopedSlots: { customRender: 'nameAndAddress' },
      slots: { title: "nameAndAddress" },
      sorter: (a, b) => sortStringAttributes(a.name, b.name),
    },
    {
      title: "LA Full Name",
      scopedSlots: { customRender: 'fullName' },
      slots: { title: "fullName" },
      sorter: (a, b) => sortStringAttributes(a.firstName, b.firstName),
    },
    {
      title: "LA Email",
      dataIndex: "email",
      sorter: (a, b) => sortStringAttributes(a.email, b.email),
    },
    {
      title: "LA Contact No",
      dataIndex: "contactNumber",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => sortStringAttributes(a.createdAt, b.createdAt),
      slots: { title: "createdAt" },
      key: 'createdAt',
      scopedSlots: { customRender: 'createdAt' },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      sorter: (a, b) => sortStringAttributes(a.updatedAt, b.updatedAt),
      slots: { title: "updatedAt" },
      key: 'updatedAt',
      scopedSlots: { customRender: 'updatedAt' },
    },
    {
      title: "Action",
      slots: { title: "Action" },
      scopedSlots: { customRender: 'Action' },
      fixed: 'right',
      width: 150
    }
  ];

  visible = false;
  
  @LocationModule.Action
  public load!: () => Promise<Location[]>;

  @BusinessModule.Action
  public loadBusinesses: () => Promise<Business[]>;

  @LocationModule.Action
  public search!: (term:string) => Promise<Location[]>;

  @LocationModule.Action
  public reset!: () => Promise<void>;

  get modalTitle() {
    return this.activeMode === 'add' ? 'Add Location' : 'Edit Location';
  }

  getBusinessName(businessId:string) {
    const response = this.businesses.filter(record => record.businessId === businessId)
    if (response.length > 0) return response[0].name;
    return '';
  }

  async beforeDestroy() {
    this.reset();
  }

  async mounted() {
    this.locations = [];
    this.fetching = true;
    try {
      if (this.profile.scope === 'GSA') {
        this.columns.unshift({
          title: "Business",
          scopedSlots: { customRender: 'businessName' },
          slots: { title: "businessName" },
          sorter: (a, b) => sortStringAttributes(a.name, b.name),
        })
        await this.loadBusinesses();
      }
      await this.load();
    } catch(err) {
      console.log(err);
    }
    this.fetching = false;
  }

  navigate(to) {
    this.$router.push(to);
  }

  async locationAdded() {
    this.visible = false;
    try {
      await this.load();
    } catch(err) {
      console.log(err);
    }
  }

  onSearch(term) {
    this.searchTerm = term;
    this.search(term);
    console.log("searched", term);
  }

  customRow(record) {
    return {
      on: {
        click: (event) => {
          this.$router.push(`/location/view/${record.locationId}`);
        },
      },
    };
  }
}
